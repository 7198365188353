import React, { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { ConsultantModel, IFournisseurResponsableModel, IHttpErrorResponseModel } from "../../../../models"
import axios, { AxiosError } from "axios"
import { toast } from "react-toastify"
import { API_ESPACE_FOURNISSEUR_RESP_GET_COLLABORATEURS_ENDPOINT, CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION } from "../../../../constants"
import { Link } from "react-router-dom"
import MyAlert from "../../../../utils/MyAlert"
import { useAuth } from "../../../../modules/auth"
import MyTooltip from "../../../../utils/MyTooltip"
import MyModal from "../../../../utils/MyModal"
import ChangementDeResponsable from "../ChangementDeResponsable"
import moment from "moment/moment"

const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"

const FournisseurRespGetCollaborateurs = () => {
     const queryClient = useQueryClient()
     const { currentUser } = useAuth()
     const [currentPage, setCurrentPage] = useState(1)
     const [searchTerm, setSearchTerm] = useState("") // state for search term
     const itemsPerPage = 8 // Number of items per page
     const [changementRespInModal, setChangementRespInModal] = useState<{
          isModalVisible: boolean
          consultants: null | ConsultantModel[]
     }>({
          isModalVisible: false,
          consultants: null,
     })

     const listingQuery = useQuery<ConsultantModel[], AxiosError>(REACT_QUERY_KEY_GET_LISTING, async () => {
          return axios
               .post(API_ESPACE_FOURNISSEUR_RESP_GET_COLLABORATEURS_ENDPOINT, {})
               .then(r => r.data)
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data
                    toast.error(error?.detail, { autoClose: false })
                    throw e
               })
     })

     // Function to handle page change
     const handlePageChange = (pageNumber: number) => {
          setCurrentPage(pageNumber)
     }

     const handleSuccessCallbackChangementResp = (nouveauResp: IFournisseurResponsableModel) => {
          queryClient.setQueryData<ConsultantModel[] | undefined>(REACT_QUERY_KEY_GET_LISTING, prev => {
               if (prev) {
                    const index = listingQuery.data!.findIndex(item => item.id === changementRespInModal.consultants![0].id)
                    if (index >= 0) {
                         prev[index] = {
                              ...prev[index],
                              sousTraitant: {
                                   ...prev[index].sousTraitant,
                                   fournisseurResponsable: {
                                        ...prev[index].sousTraitant?.fournisseurResponsable,
                                        ...nouveauResp,
                                   },
                              },
                         }
                    }
               }
               return prev
          })
          setChangementRespInModal({ consultants: null, isModalVisible: false })
     }

     if (listingQuery.isFetching) {
          return (
               <div className={"text-center p-4"}>
                    <span className="spinner-border mb-2"></span>
                    <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
               </div>
          )
     }

     if (!listingQuery.isFetching && listingQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         {listingQuery.error.response?.data?.detail}
                         <button className={"btn btn-sm btn-danger ms-2"} onClick={() => listingQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     if (!listingQuery.isFetching && !listingQuery.isError && listingQuery.data) {
          // Filtered data based on search term
          const filteredData = listingQuery.data.filter(item => {
               const fullName = `${item.prenom} ${item.nom}`.toLowerCase()
               return fullName.includes(searchTerm.toLowerCase())
          })

          const offset = (currentPage - 1) * itemsPerPage
          const currentItems = filteredData.slice(offset, offset + itemsPerPage)
          const pageCount = Math.ceil(filteredData.length / itemsPerPage)

          return (
               <>
                    {/* Search Input */}
                    <div className="w-xxl-25 w-xl-30 w-md-50">
                         <input
                              type="text"
                              className="form-control mb-3"
                              placeholder="Rechercher un collaborateur ..."
                              value={searchTerm}
                              onChange={e => setSearchTerm(e.target.value)}
                         />
                    </div>

                    <div className="table-responsive text-nowrap" style={{ overflowY: "hidden" }}>
                         <table className={`table table-rounded gy-5 gs-5 m-0 p-0`}>
                              <thead className={`bg-light border-bottom border-secondary`}>
                                   <tr>
                                        <th>Entité</th>
                                        <th>Nom</th>
                                        <th>Entrée le</th>
                                        <th>Etat</th>
                                        <th>Responsable</th>
                                        <th>Contact CTS</th>
                                        <th>Actions</th>
                                   </tr>
                              </thead>
                              <tbody>
                                   {filteredData.length === 0 ? (
                                        <tr>
                                             <td colSpan={6} className={"bg-light-primary text-center"}>
                                                  Aucun résultat
                                             </td>
                                        </tr>
                                   ) : (
                                        currentItems.map(item => (
                                             <tr key={item.id} className={"align-middle"}>
                                                  <td>{item.entite.nom}</td>
                                                  <td>
                                                       <Link to={`/espace-resp-fournisseur/collaborateurs/${item.id}/details`}>{item.prenomNom}</Link>
                                                  </td>
                                                  <td>
                                                       {item.dateEmbauche ? (
                                                            <MyTooltip title={`Entrée le ${item.dateEmbauche?.format("dddd DD MMMM YYYY")}`} placement={"bottom"}>
                                                                 <span>{item.dateEmbauche?.format("DD/MM/YYYY")}</span>
                                                            </MyTooltip>
                                                       ) : (
                                                            <span className={"text-muted"}>Non renseignée</span>
                                                       )}
                                                  </td>
                                                  <td>
                                                       {item.etat === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION ? (
                                                            <span className={"badge badge-info"}>En cours</span>
                                                       ) : item.dateSortie?.isBefore(moment()) ? (
                                                            <span className={"badge badge-danger"}>Inactif</span>
                                                       ) : (
                                                            <span className={"badge badge-success"}>Actif</span>
                                                       )}
                                                  </td>
                                                  <td>{item.sousTraitant?.fournisseurResponsable?.prenomNom}</td>
                                                  <td>
                                                       <a href={`mailto:${item.personnel?.mailProfessionnel}`} target={"_blank"}>
                                                            {item.personnel?.prenomNom}
                                                       </a>
                                                  </td>
                                                  <td className={"d-flex gap-5"}>
                                                       <Link
                                                            to={`/espace-resp-fournisseur/collaborateurs/${item.id}/details`}
                                                            className="btn btn-primary btn-sm px-3 py-2 d-flex align-items-center"
                                                       >
                                                            Consulter <span className={"fas fa-eye ms-1 fs-4"} />
                                                       </Link>
                                                       {currentUser?.user?.roles?.includes("ROLE_RESPONSABLE_FOURNISSEUR_ADMIN") && (
                                                            <button
                                                                 className="btn btn-warning btn-sm px-3 py-2 d-flex align-items-center"
                                                                 onClick={() => {
                                                                      setChangementRespInModal({
                                                                           isModalVisible: true,
                                                                           consultants: [item],
                                                                      })
                                                                 }}
                                                            >
                                                                 Changer de responsable <span className={"fas fa-user ms-1 fs-4"} />
                                                            </button>
                                                       )}
                                                  </td>
                                             </tr>
                                        ))
                                   )}
                              </tbody>
                         </table>
                    </div>

                    {/* Pagination */}
                    {filteredData.length > 0 && (
                         <>
                              <div className="separator mb-5" />
                              <nav>
                                   <ul className="pagination justify-content-center">
                                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                             <button className="page-link border" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                                  Précédant
                                             </button>
                                        </li>
                                        {[...Array(pageCount)].map((_, index) => (
                                             <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                                                  <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                       {index + 1}
                                                  </button>
                                             </li>
                                        ))}
                                        <li className={`page-item ${currentPage === pageCount ? "disabled" : ""}`}>
                                             <button className="page-link border" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>
                                                  Suivant
                                             </button>
                                        </li>
                                   </ul>
                              </nav>
                         </>
                    )}

                    {changementRespInModal.isModalVisible && (
                         <MyModal
                              title={<>Changement de responsable de {changementRespInModal!.consultants![0]!.prenomNom}</>}
                              show={true}
                              handleClose={() => setChangementRespInModal(prev => ({ ...prev, isModalVisible: false }))}
                         >
                              <ChangementDeResponsable
                                   consultants={changementRespInModal.consultants!}
                                   onCancelCallback={() => setChangementRespInModal(prev => ({ ...prev, isModalVisible: false }))}
                                   onSuccessCallback={handleSuccessCallbackChangementResp}
                              />
                         </MyModal>
                    )}
               </>
          )
     } else {
          return <div>Loading...</div>
     }
}

export { FournisseurRespGetCollaborateurs }
